import React, {useEffect, useState} from 'react';
import {graphql} from 'gatsby';

import {useLocale} from '@app/hooks';

import {
  BeautyCard,
  Card,
  CardTitle,
  FashionCard,
  Layout,
  NewsCard,
  Post,
  Seo,
  VideoCard,
} from '@app/components';
import slugify from 'slugify';

type HomePropTypes = {
  data: {
    headline: PostPropType;
    carousel: PostPropType;
    wellnessPost: PostPropType;
    centerPost: PostPropType;
    video: PostPropType;
    yuzEstetigi: PostPropType;
    estetikCerrahi: PostPropType;
    medicalEsthetic: PostPropType;
    memeEstetigi: PostPropType;
    yagEnjeksiyonu: PostPropType;
    healthTabs: PostPropType;
    genitalEstetik: PostPropType;
    blogPost: PostPropType;
  };
  pageContext: {
    language: string;
  };
};

type PostPropType = {
  nodes: {
    excerpt: string;
    frontmatter: {
      title: string;
      author: string;
      date: string;
      cover: any;
      imageUrl: string;
    };
  }[];
};

const Home: React.FC<HomePropTypes> = ({data, pageContext}) => {
  const SCOPE_OPTIONS = {
    scope: 'pages.Index',
  };
  const {t} = useLocale();

  return (
    <Layout boxed>
      <Seo title={t('labels.metaTitle', SCOPE_OPTIONS)} />
      <div className={'content-block'}>
        <Post.Carousel posts={data.headline.nodes} />

        <div className="px-6 py-4 layout__inner">
          <div className="grid lg:grid-cols-12 gap-8 mb-8">
            <div className="lg:col-span-7">
              <BeautyCard
                title={'Yüz Estetiği'}
                titleColor="yellow"
                disableTitle={true}
                single={true}
                beautyCardList={[
                  {
                    title: '',
                    posts: [data.yuzEstetigi.nodes[0]],
                  },
                ]}
              />
            </div>
            <div className="lg:col-span-5 mb-10 news-card-container mt-10">
              <div className="news-card-title font-bold text-2xl p-4">Blog</div>

              <div className="news-cards">
                {data.blogPost.nodes.map((post) => (
                  <NewsCard
                    title={
                      post.frontmatter.title.includes(':')
                        ? post.frontmatter.title.split(':')[1].trim(' ')
                        : post.frontmatter.title
                    }
                    link={`/${slugify(post.frontmatter.title, {
                      lower: true,
                      strict: true,
                    })}`}
                    description={post.excerpt}
                    date={post.frontmatter.date}
                    category={'Blog'}
                    cover={post.frontmatter?.cover?.childImageSharp?.fluid?.src}
                    imageUrl={post.frontmatter?.imageUrl}
                  />
                ))}
              </div>
            </div>
          </div>

          <div className="pt-4 pb-8 lg:pt-8">
            <CardTitle title="SBB TV" titleColor="black" />
            <div className="grid lg:grid-cols-12 gap-8 mb-8">
              {data.video.nodes.map((video, index) => (
                <div className="lg:col-span-4">
                  <VideoCard
                    key={index}
                    title={video.frontmatter.title}
                    description={video.excerpt}
                    author={video.frontmatter.author}
                    date={video.frontmatter.date}
                    url={video.frontmatter.url}
                    cover={video.frontmatter?.cover?.childImageSharp.fluid}
                    imageUrl={video.frontmatter.imageUrl}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="grid lg:grid-cols-12 gap-8 mb-8">
            <div className="lg:col-span-5">
              <div className="news-card-title font-bold text-2xl p-4">
                Cilt Bakımı
              </div>

              <div className="news-cards">
                {data.wellnessPost.nodes.map((author, index) => (
                  <NewsCard
                    key={index}
                    title={author.frontmatter.title}
                    description={author.excerpt}
                    date={author.frontmatter.date}
                    category={'Wellness'}
                    author={author.frontmatter.author}
                    cover={author.frontmatter?.cover?.childImageSharp.fluid.src}
                  />
                ))}
              </div>
            </div>
            <div className="lg:col-span-7">
              <Card
                title={data.centerPost.nodes[0].frontmatter.title}
                description={data.centerPost.nodes[0].excerpt}
                author={data.centerPost.nodes[0].frontmatter.author}
                date={data.centerPost.nodes[0].frontmatter.date}
                cover={
                  data.centerPost.nodes[0].frontmatter.cover?.childImageSharp
                    .fluid
                }
              />
            </div>
          </div>

          <div className="pt-8 pb-8">
            <BeautyCard
              title={'Medikal Estetik'}
              titleColor="red"
              beautyCardList={[
                {
                  title: '',
                  posts: data.medicalEsthetic.nodes,
                },
              ]}
            />
          </div>

          <div className="pt-8 pb-8">
            <FashionCard
              title={'Meme Estetiği'}
              titleColor="pink"
              fashionCardList={[
                {
                  title: '',
                  posts: data.memeEstetigi.nodes,
                },
              ]}
            />
          </div>

          <div className="pt-8 pb-8">
            <BeautyCard
              title={'Yağ Enjeksiyonu'}
              titleColor="green"
              beautyCardList={[
                {
                  title: '',
                  posts: data.yagEnjeksiyonu.nodes,
                },
              ]}
            />
          </div>

          <div className="pt-8 pb-8">
            <FashionCard
              title={'Estetik Cerrahi'}
              titleColor="purple"
              fashionCardList={[
                {
                  title: '',
                  posts: data.estetikCerrahi.nodes,
                },
              ]}
            />
          </div>

          <div className="pt-8 pb-8">
            <BeautyCard
              title={'Yüz Estetiği'}
              titleColor="yellow"
              beautyCardList={[
                {
                  title: '',
                  posts: data.yuzEstetigi.nodes.slice(0, 3),
                },
              ]}
            />
          </div>

          <div className="pt-8 pb-8">
            <FashionCard
              title={'Genitial Estetik'}
              titleColor="brown"
              fashionCardList={[
                {
                  title: '',
                  posts: data.genitalEstetik.nodes,
                },
              ]}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Home;

export const pageQuery = graphql`
  query ($language: String!) {
    headline: allMarkdownRemark(
      sort: {fields: frontmatter___date, order: DESC}
      filter: {
        frontmatter: {
          language: {eq: $language}
          categories: {
            nin: ["Dergi", "Magazine", "Ajanda", "Agenda", "Youtube"]
          }
        }
      }
      limit: 15
    ) {
      nodes {
        excerpt(pruneLength: 280)
        frontmatter {
          title
          author
          date(formatString: "MMMM D, YYYY", locale: $language)
          cover {
            publicURL
            childImageSharp {
              resize(width: 150, height: 150) {
                src
              }
            }
          }
        }
      }
    }

    wellnessPost: allMarkdownRemark(
      sort: {fields: frontmatter___date, order: DESC}
      filter: {
        frontmatter: {
          language: {eq: $language}
          categories: {in: ["Cilt Bakımı"]}
        }
      }
      limit: 12
    ) {
      nodes {
        excerpt(pruneLength: 80)
        frontmatter {
          title
          author
          date(formatString: "MMMM D, YYYY", locale: $language)
          cover {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }

    blogPost: allMarkdownRemark(
      sort: {fields: frontmatter___date, order: DESC}
      filter: {
        frontmatter: {language: {eq: $language}, categories: {in: ["Blog"]}}
      }
      limit: 12
    ) {
      nodes {
        excerpt(pruneLength: 80)
        frontmatter {
          title
          author
          date(formatString: "MMMM D, YYYY", locale: $language)
          cover {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }

    centerPost: allMarkdownRemark(
      sort: {fields: frontmatter___date, order: DESC}
      filter: {
        frontmatter: {
          language: {eq: $language}
          categories: {nin: ["Dergi", "Magazine"]}
        }
      }
      skip: 4
      limit: 1
    ) {
      nodes {
        excerpt(pruneLength: 280)
        frontmatter {
          title
          author
          date(formatString: "MMMM D, YYYY", locale: $language)
          cover {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }

    video: allMarkdownRemark(
      sort: {fields: frontmatter___date, order: DESC}
      filter: {
        frontmatter: {language: {eq: $language}, categories: {eq: "Youtube"}}
      }
      limit: 3
    ) {
      nodes {
        excerpt(pruneLength: 130)
        frontmatter {
          title
          author
          date(formatString: "MMMM D, YYYY", locale: $language)
          cover {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }

    yuzEstetigi: allMarkdownRemark(
      sort: {fields: frontmatter___date, order: DESC}
      filter: {
        frontmatter: {
          language: {eq: $language}
          categories: {in: ["Yüz Estetiği"]}
        }
      }
      limit: 3
    ) {
      nodes {
        excerpt(pruneLength: 400)
        frontmatter {
          title
          date(formatString: "MMMM D, YYYY", locale: $language)
          cover {
            childImageSharp {
              resize(width: 910, height: 600) {
                src
              }
            }
          }
        }
      }
    }

    estetikCerrahi: allMarkdownRemark(
      sort: {fields: frontmatter___date, order: DESC}
      filter: {
        frontmatter: {
          language: {eq: $language}
          categories: {in: ["Estetik Cerrahi"]}
        }
      }
      limit: 3
    ) {
      nodes {
        excerpt(pruneLength: 400)
        frontmatter {
          title
          date(formatString: "MMMM D, YYYY", locale: $language)
          cover {
            childImageSharp {
              resize(width: 910, height: 600) {
                src
              }
            }
          }
        }
      }
    }

    medicalEsthetic: allMarkdownRemark(
      sort: {fields: frontmatter___date, order: DESC}
      filter: {
        frontmatter: {
          language: {eq: $language}
          categories: {in: ["Medikal Estetik"]}
        }
      }
      limit: 3
    ) {
      nodes {
        excerpt(pruneLength: 400)
        frontmatter {
          title
          date(formatString: "MMMM D, YYYY", locale: $language)
          cover {
            childImageSharp {
              resize(width: 910, height: 600) {
                src
              }
            }
          }
        }
      }
    }

    memeEstetigi: allMarkdownRemark(
      sort: {fields: frontmatter___date, order: DESC}
      filter: {
        frontmatter: {
          language: {eq: $language}
          categories: {in: ["Meme Estetiği"]}
        }
      }
      limit: 3
    ) {
      nodes {
        excerpt(pruneLength: 400)
        frontmatter {
          title
          date(formatString: "MMMM D, YYYY", locale: $language)
          cover {
            childImageSharp {
              resize(width: 910, height: 600) {
                src
              }
            }
          }
        }
      }
    }

    yagEnjeksiyonu: allMarkdownRemark(
      sort: {fields: frontmatter___date, order: DESC}
      filter: {
        frontmatter: {
          language: {eq: $language}
          categories: {in: ["Yağ Enjeksiyonu"]}
        }
      }
      limit: 3
    ) {
      nodes {
        excerpt(pruneLength: 400)
        frontmatter {
          title
          date(formatString: "MMMM D, YYYY", locale: $language)
          cover {
            childImageSharp {
              resize(width: 910, height: 600) {
                src
              }
            }
          }
        }
      }
    }

    healthTabs: allMarkdownRemark(
      sort: {fields: frontmatter___date, order: DESC}
      filter: {
        frontmatter: {
          language: {eq: $language}
          categories: {in: ["Cilt Estetiği"]}
        }
      }
      limit: 3
    ) {
      nodes {
        excerpt(pruneLength: 400)
        frontmatter {
          title
          date(formatString: "MMMM D, YYYY", locale: $language)
          cover {
            childImageSharp {
              resize(width: 910, height: 600) {
                src
              }
            }
          }
        }
      }
    }

    genitalEstetik: allMarkdownRemark(
      sort: {fields: frontmatter___date, order: DESC}
      filter: {
        frontmatter: {
          language: {eq: $language}
          categories: {in: ["Genital Estetik"]}
        }
      }
      limit: 3
    ) {
      nodes {
        excerpt(pruneLength: 400)
        frontmatter {
          title
          date(formatString: "MMMM D, YYYY", locale: $language)
          cover {
            childImageSharp {
              resize(width: 910, height: 600) {
                src
              }
            }
          }
        }
      }
    }

    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
